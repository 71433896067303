// Here you can add other styles

.table-responsive {
	overflow-x: visible;
}

#projects_overview th,
#users_overview th,
#questions_overview th {
	border-top: 0;	
}

#questions_overview tr {
    border-left: 1px solid #d8dbe0;
    border-right: 1px solid #d8dbe0;
}

#questions_overview table {
	border-bottom: 1px solid #d8dbe0;
}

#questions_overview input.form-control {
	border-bottom-right-radius: 0;
}

.carousel-item {
	transition: transform 0.3s ease-in-out;
}

/* Make the dropdown menu appear within the table */
@media only screen and (max-width: 600px) {
	.table-responsive {
		overflow-x: visible;
	}
}

.clickable {
	cursor: pointer;
}

/* ReactTags example */
.ReactTags__tags {
	position: relative;
}

.ReactTags__tagInput {
	margin-top: 5px;
}

.ReactTags__tagInputField {
	border: 1px solid #d8dbe0;
	border-radius: 4px;
	padding: 3px;		
}

.ReactTags__selected {
}

.ReactTags__tag {
	border: 1px solid #d8dbe0;
	border-radius: 4px;

	background-color: #FEFEFE;		
	display: inline-block;	
	margin-right: 3px;
	padding-left: 5px;	
	padding-right: 5px;	
	padding-top: 3px;
	padding-bottom: 3px;

}

.ReactTags__selected ReactTags__tag {

}

.ReactTags__remove {
	margin-left: 5px;
	margin-right: 2px;
	cursor: pointer;
	
	border: none;
	background-color: transparent;	
}

.ReactTags__suggestions {
	top: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 4px 11px;
    margin-bottom: 8px;
    margin-top: 8px;
    position: absolute;
    width: 100%;
    z-index: 2;
    box-sizing: border-box;
    border-radius: 4px;		

    max-height: 200px;
    overflow-y: scroll;
    z-index: 222;
}

.ReactTags__suggestions ul {
	margin: 0;
	padding: 0;
}

.ReactTags__activeSuggestion, .ReactTags__suggestions ul li  {
    color: inherit;
    cursor: default;
    display: block;
    font-size: inherit;
    width: 100%;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    padding: 8px 12px;		
}

.ReactTags__activeSuggestion mark, .ReactTags__suggestions ul li mark {
	font-weight: bold;
	padding-right: 0;
}

.ReactTags__activeSuggestion:hover, .ReactTags__suggestions ul li:hover {
	background-color: rgb(222, 235, 255);
}

.carousel-show-overflow .carousel-inner {
	overflow: visible;
}

.answerSelectorButton {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.answerSelectorButton .c-icon,
.questionSearchBox .c-icon,
.answerSearchBox .c-icon, 
.unlockableSearchBox .c-icon,
.templateSearchBox .c-icon {
	margin: 0;
}

.questionSearchBox,
.answerSearchBox,
.unlockableSearchBox,
.templateSearchBox {
	border-bottom-left-radius: 0;
}

.answerPreview {
	white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.answerDropdown {
	width: 400px;
	box-shadow: 0px 0px 9px rgba(0,0,0,0.15);

	/* Popper.js flip property not applied. Override! */
	/* transform: translate(-339px, 35px) !important;
	bottom: auto !important;
	top: 0 !important; */
}

.moreAnswers, .uploadAnotherImage, .createAnswer, .searchInstead {
	text-decoration: underline !important;
	cursor: pointer !important;
	color: grey;
}

.exampleAnswer {
	margin-right: 4px;
}

.previewImage {
	max-width: 200px;
	max-height: 200px;
	margin-bottom: 10px;
}

.toaster .toast {
	
}

.toaster .toast.success {
	background-color: #d5f1de;
    color: #18603a;
}

.toaster .toast.success .toast-header {
	background-color: rgba(255, 255, 255, 0.6);
}

.questionType-preview {
	height: 130px;
	display: inline-block;
	margin-top: 15px;
}

.questionType-description {
	text-align: center;
}

#questions_overview table .badge {
	vertical-align: text-bottom;
}

div.image-preview-container {
	text-align: center;
}

img.image-preview {
	display: block;
	width: 150px;
	max-height: 150px;
	height: auto;
}

.btn-group-sm > .btn.smallButton {
	padding: 1px 5px;
}

#levelQuestions button.dropdown-toggle {
	padding-right: 0;
}

#levelQuestions button.dropdown-toggle.btn::after {
	display: none;
}

#levelQuestions .addQuestionDropdown {
	width: 400px;
	box-shadow: 0px 0px 9px rgba(0,0,0,0.15);
	padding: 10px;
	z-index: 2222;
}

#levelQuestions .addQuestionDropdown thead {
	display: none
}

#levelQuestions .my-5 {
	margin-top: 15px !important;
	margin-bottom: 0 !important;
}

.greyText {
	color: #999;
}

.conditionRow {
	padding: 15px
}

.input-group-prepend {
	cursor: pointer;
}

.conditionRow .input-group small {
	font-size: 65%;
}

#history h5.username {
	margin-left: 10px;
	margin-top: 9px;
	font-size: 14px;
}

#history ul {
  margin: 0;
}

#history ul.dashed {
  list-style-type: none;
}

#history ul.dashed > li {
  text-indent: -5px;
  margin-bottom: 5px;
  margin-left: 10px;
}

#history ul.dashed > li:before {
  content: "- ";
  text-indent: -5px;
}

#history .entityTitle {
	padding: 5px;
	background-color: #ECECEC;
	border-radius: 3px;	

	margin-top: 5px;
	display: inline-block;	
}

#history .appTitle {
	padding: 5px;
	background-color: #ECECEC;
	border-radius: 3px;	

	display: inline-block;	
	margin-bottom: 3px;	
}

#history h3.dateGroup {
	margin-top: 25px;
	margin-bottom: 15px;
	font-size: 12px;
	color: gray;
}

#history .userGroup {
	margin-left: 0;
	margin-right: 0;
	margin-bottom: 25px;
}

#history .appGroup {
	margin-top: 6px;
	margin-bottom: 20px;
}

#history h6 {
	color: gray;
	font-size: 12px;
}

.profileImage {
	width: 36px;
	height: 36px;
}

.c-avatar.big {
	width: 128px;
	height: 128px;
}

.newProfileImage {
	width: 128px;
	height: 128px;
}

.c-avatar-img {
	height: 100%;
}

.pointer {
	cursor: pointer;
}

td.editingTableData input {
    -webkit-appearance: none;
	outline: none;  
}

td.editingTableData .form-control:focus {
	color: #768192;
	background-color: #fff;
	border-color: #DFDFDF;
	outline: 0;
	box-shadow: none;
}

input#newTranslation, input#editTranslation {
	height: calc(1.5em + 0.75rem + 6px);
}

#languageInfoModal .input-group-prepend .btn,
#languageInfoModal .input-group-append .btn {
	z-index: 0;
}

#editTranslation .input-group-prepend .btn,
#editTranslation .input-group-append .btn {
	z-index: 0;
}


#project button.dropdown-toggle.btn::after {
	color: white;
}

#project .dropdown-item:active {
	background-color: #E3E3E3;
}

#createReleaseModal .c-icon.releaseState {
	width: 3rem;
	height: 3rem;
	margin-bottom: 15px;
}

#releases_overview tr {
	height: 35px;
}

tr.deployed {
	border-left: 3px solid #2eb85c;
}

.react-datepicker__input-container {
	height: 100%;
	border-color: #d8dbe0;
}

.react-datepicker__input-container .dateInput {
	height: 100%;
	width: 100%;
	padding: 4px;

}

.react-datepicker__input-container .dateInput:disabled {
	color: #768192;
	background-color: #d8dbe0;
	opacity: 1;	
	border: 0;
}

.visibilityState {
	padding-top: 10px;
	padding-bottom: 10px;
	display: block;
}

.visibilityState.green {
	color: green;
}

.visibilityState.red {
	color: red;
}

.answerSelectBox {
	background-color: #EAF0F1;
	padding: 15px;
	padding-bottom: 0;
	border-radius: 4px;
	margin-bottom: 15px;
	border: 1px solid #D4D5D4;
}

.answerOption {
	display: flex;
	flex-direction: row;
	padding: 5px; 
}

.answerOption .imageContainer {
	width: 48px;
	height: 48px;
	padding: 3px;
	background-color: rgba(0, 0, 0, 0.1);
	border-radius: 3px;

    display: -webkit-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;    
}

.answerOption .imageContainer img {
	display: inline-block;
	min-width: 48px;
	max-width: 48px;

	min-width: 32px;
	min-height: 32px;
}

.answerOption:hover {
	background-color: #DEEBFF;
}

.answerOption.active {
	background-color: #2684FF;
	color: white;
}

.answerOptionInner {
	margin-left: 15px;
	margin-top: 5px;
}

.answerOption h3 {
	font-size: 16px;
	margin: 0;
	padding: 0;
}

.answerOption span {
	font-size: 12px;
}

.questionImageEnabler .c-switch-slider {
	margin-bottom: 0.5rem;
	margin-top: 5px;
}

.questionImageEnabler label.c-switch {
	margin-right: 5px;
}

img.selectedCorrectAnswerImage {
	max-width: 32px;
	max-height: 32px;
	margin-right: 4px;
}

.answerSelectorHeading {
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 14px
}

.template-string-input {
	flex: 1 1 auto;
	min-height: 38px;
}

.template-string-input .select__control {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-color: #d8dbe0;
	z-index: 100
}

.template-string-input .select__control:not(.select__control--is-focused) {
	border-right: 0;
}

.action-cell {
	max-width: 60px;
	width: 30px;
}

.action-cell-button {
	padding: 1px 4px;
}

.action-cell-menu {
	min-width: auto;
	padding: 10px
}

.localizable-toggle-button {
	position: absolute;
	top: 6px;
	right: 7px;
	width: 24px;
	height: 24px;
	cursor: pointer;
}

.question_preview_popover div {
	margin-top: 8px;
}

.question_preview_popover .image-preview {
	margin-bottom: 16px;
}

.questionFeatureIcons {
	background-color: #ced2d8;
	border-radius: 8px;
	margin-right: 6px;
	padding: 1px 5px 3px 5px;
}

.questionFeatureIcons .question-icon {
	margin: 2px 1px;
}

.questionFeatureIcons .question-icon path {
	fill: #4f5d73;
}

.localization-preview {
	width: 1rem;
	height: 1rem;
	margin-left: 3px;
}

.localization-preview path {
	fill: #E45253;
}

.localization-preview line {
	stroke: #E45253;
}

.localization-preview path.active {
	fill: #40B75C;
}

.form-control:disabled, .form-control[readonly] {
	color: #3a3f47;
}

.select__menu {
	z-index: 222 !important;
}

.tippy-content {
	max-width: 400px;
}

.clearAnswerContext {
	cursor: pointer;
	margin: 3px;
}

.action-arrow,
.hover-info-indicator  {
	opacity: 0;
	outline: none;
}

.hover-info-indicator {
	cursor: default !important;
}

.hover-info-indicator {
	width: 5px;
	top: 0;
	left: 0;
	height: 20px;
	border-radius: 2px;
	position: absolute;
	display: inline-block;
	background-color: black;
}

.draggable-question-cell:hover .action-arrow,
.draggable-question-cell:hover .hover-info-indicator {
	opacity: 1;
}

.duplicate-question-list {
	margin-top: 10px;
}

.duplicate-question-list .question-list-item {
	background-color: rgba(0,0,0,0.1);
	margin-right: 3pt;
	margin-bottom: 3pt;
	padding: 6pt;
	cursor: pointer;
	display: inline-block;
}

#category_overview .questionPopoverCell {
    overflow: hidden;	
}

.draggable-question-cell .hoverInfo {
    width: 32px;
    opacity: 0;
}

.draggable-question-cell:hover .hoverInfo {
	opacity: 1;
}

.draggable-question-cell .hoverInfo .question,
.draggable-question-cell .hoverInfo .answer {
	position: absolute;
	left: 2px;
	cursor: help;

	display: block;
	border-radius: 6px;
	background-color: rgba(0,0,0,0.0);
	width: 12px;
	height: 12px;

	text-align: center;
	font-size: 8px;
	font-weight: bold;
	z-index: 222;
}

.draggable-question-cell .hoverInfo .question {
	top: 2px;
}

.draggable-question-cell .hoverInfo .answer {
	top: 13px;
}